@use '../../../../../shared/styles/fixed' as *;
@use '../../../../../shared/ui/typography/sizes' as *;
@use '../../../../../app/queries' as *;

.card-price-item-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  overflow: hidden;
  border-radius: var(--cornerradius10);
  box-shadow: inset 0 0 0 2px #f6f6f6;
  background-color: #fafafa;
  @include fixedSize(100%, 66px);

  @include min-1440-break {
    @include fixedSize(100%, 73px);
  }

  .content {
    display: flex;
    width: 100%;
    flex-direction: column;
    .text {
      color: var(--cst-cards-item-page-description);
      @include typography-base;
      @include weight-medium;
    }
  }
}

.background {
  position: relative;
  width: 196px;
  height: 196px;
  display: flex;
  transform: translate(-70px, 0);
  justify-content: center;
  align-items: center;

  .card-decoration {
    position: absolute;
    z-index: 0;
    left: 86px;
    bottom: 0px;
    rotate: 12deg;
    scale: 0.9;

    @include min-1440-break {
      left: 66px;
      bottom: 0px;
    }

    @include min-1920-break {
      left: 65px;
      bottom: 0px;
    }
  }
}
